import React from 'react';
import { topography } from 'hero-patterns';

const borderStyles = {
    backgroundColor: 'var(--border-color)',
    padding: '2rem',
};

const contentStyles = {
    backgroundColor: 'var(--background-color)',
    borderRadius: '6px',
    transition: 'background-color 0.3s',
    boxShadow:
        '0 1.3px 5.4px rgba(0, 7, 8, 0.6),0 4.5px 18.1px rgba(0, 7, 8, 0.4), 0 20px 81px rgba(0, 7, 8, 0.1)',
};

const Border = ({ children }) => (
    <div
        style={{ ...borderStyles, backgroundImage: topography('#FFC5B1', 0.5) }}
    >
        <div style={contentStyles}>{children}</div>
    </div>
);

export default Border;
